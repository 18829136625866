<template>
  <b-container fluid>
    <div v-if="!loading">
      <b-row v-if="chart" class="justify-content-center">
        <b-col cols="8" class="m-50">
          <b-progress 
            :max="total_hits"
            height="20px"
          >
            <b-progress-bar
              variant="success"
              :value="success_rate"
              animated
            />
            <b-progress-bar
              variant="danger"
              :value="failure_rate"
              animated
            />
          </b-progress>
        </b-col>
        <b-col cols="12" class="mt-50">
            <h6 class="text-center text-muted font-weight-bolder text-uppercase">
              Hits {{ success_rate }}/{{ total_hits }} success rate {{ ((success_rate / Math.max(total_hits, 1)) * 100).toFixed(2) }}%
            </h6>
          </b-col>
        <b-col cols="12">
          <vue-apex-charts
            type="area"
            height="400"
            length="100%"
            :options="options.chartOptions"
            :series="options.series"
          />
        </b-col>
        <b-col cols="12" v-if="data">
          <h6 class="mb-3 text-center text-muted font-weight-bolder">From {{ formatDate(this.chart.timestamp[0]) }} to {{ formatDate(to*1000) }} with an interval of {{ chart.interval }}</h6>
        </b-col>
      </b-row>
    </div>
    <b-row v-else class="justify-content-center">
      <b-col cols="8" class="mb-1">
        <b-skeleton type="input" class="rounded-pill" height="25px"/>
      </b-col>
      <b-col cols="12" class="mb-3">
        <b-skeleton-img
          no-aspect
          height="400px"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { 
  BContainer, 
  BRow, 
  BCard, 
  BCardText, 
  BLink, 
  BButton, 
  BCol, 
  BProgress, 
  BProgressBar,
  BSkeleton,
  BSkeletonImg
} from 'bootstrap-vue'

import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
  export default {
    components: {
      BCard,
      BCardText,
      BLink,
      BButton,
      VueApexCharts,
      BContainer, 
      BRow,
      BCol,
      BProgress,
      BProgressBar,
      BSkeleton,
      BSkeletonImg
    },
    props: {
      to: {
        type: [ Number, String ],
        required: true
      },
      from: {
        type: [ Number, String ],
        required: true
      }
    },
    data() {
      return {
        chart: {
          avg_time: [],
          timestamp: [],
          hits: 0,
          success_hits: [],
          failure_hits: [],
          total_hits: [],
        },
        info: null, 
        data: null,
        total_hits: null,
        success_rate: 0,
        failure_rate: 0,
        loading: false,
      }
    },
    computed: {
      schedulerID() {
        return this.$route.params.schedulerID
      },
      transmissionID() {
        return this.$route.params.transmissionID
      },
      options() {
        return {
          series: [
            {
              name: 'Total Hits',
              data: this.chart.total_hits
            },
            {
              name: 'Errors',
              data: this.chart.failure_hits,
            },
            {
              name: 'Successes',
              data: this.chart.success_hits,
            },
            
          ],
          chartOptions: {
            chart: {
              toolbar: {
                show: true,
                tools: {
                  download: false,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: false,
                  reset: true,
                },
              },
              fontFamily: 'Montserrat',
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: true,
              curve: 'smooth',
            },
            legend: {
              show: true,
              position: 'top',
              horizontalAlign: 'left',
              fontSize: '14px',
              fontFamily: 'Montserrat',
              labels: {
                colors: '#fff',
              },
            },
            grid: {
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              categories: this.chart.timestamp,
              labels: {
                show: false,
                style: {
                  colors: '#fff',
                  fontSize: '10px',
                  fontFamily: 'Montserrat',
                  fontWeight: 900,
                  cssClass: 'apexcharts-xaxis-label',
                },
                formatter: this.formatDate
              },
            },
            yaxis: {
                min: 0,
                max: Math.max(this.chart.hits * 1.2, 1),
                tickAmount: Math.min(Math.max(this.chart.hits * 1.2, 1), 15),
                labels: {
                  style: {
                    colors: '#fff',
                    fontSize: '10px',
                    fontFamily: 'Montserrat',
                    fontWeight: 900,
                    cssClass: 'apexcharts-xaxis-label',
                  },
                  formatter: (value) => {
                    return value.toFixed(0)
                  }
                },
            },
            // fill: {
            //   opacity: 1,
            //   type: 'solid',
            // },
            tooltip: {
              shared: true,
            },
            colors: ['#00cfe8', '#ea5455', '#12e066'],
            markers: {
              size: 5,
              strokeWidth: 0,
              fillOpacity: 1,
              discrete: [],
              shape: "circle",
              radius: 2,
              showNullDataPoints: false,
          }
          },
        }
      }
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        this.getScheduleStats()
      },
      getScheduleStats() {
        this.loading = true;

        this.$store.dispatch('getScheduleStats',
          {
            transmission_id: this.transmissionID,
            scheduler_id: this.schedulerID,
            filters: {
              from: this.from,
              to: this.to
            }
          }
        ).then((resp) => {
          this.data = resp
          this.formatData()
        }).catch((error) => {
          this.loading = false;
          console.log(error)
        })
      },
      formatData() {
        this.chart = {
          avg_time: [],
          timestamp: [],
          hits: 0,
          success_hits: [],
          failure_hits: [],
          total_hits: [],
          interval: null
        };
        

        let rates;

        this.total_hits = this.data.hits.total.value || 0
        this.info = this.data.aggregations.success_rate.buckets || 0
        
        rates = this.getRates(this.info)

        this.success_rate = rates.success
        this.failure_rate = rates.failure

        if (!this.data.aggregations) return

        this.graph_buckets = this.data.aggregations.graph.buckets
        this.graph_length = this.data.aggregations.graph.buckets.length
        this.chart.interval = this.data.aggregations.graph.interval

        this.graph_buckets.forEach((value) => {
          this.chart.avg_time.push(value.avg_elapsed_time.value)
          this.chart.timestamp.push(value.key)
          this.chart.total_hits.push(value.doc_count)
          this.chart.hits = (this.chart.hits < value.doc_count) ? value.doc_count : this.chart.hits

          let item_rates = this.getRates(value.success_rate.buckets)

          this.chart.success_hits.push(item_rates.success)
          this.chart.failure_hits.push(item_rates.failure)
        })

        this.loading = false;
      },
      getRates(payload) {
        let failure;
        let success;

        if (payload[0]) {
          if (payload[0].key == 0) {
            failure = payload[0] ? payload[0].doc_count : 0 
            success = payload[1] ? payload[1].doc_count : 0
          } else {
            failure = payload[1] ? payload[1].doc_count : 0 
            success = payload[0] ? payload[0].doc_count : 0
          }
        } else {
          failure = 0
          success = 0
        }

        return { success, failure }
      },
      formatDate(value) {
        if (!value) return null
        let date = new Date(value)
        return moment(date).format(this.$t('scheduler.dashboard.moment_date_format'));
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>