export default class EnumScheduleInterval {
  constructor() {
      this.items = [
          {
              "id": 1,
              "text": "scheduler.enum.interval.reset",
              "value": 0,
              "variant": "secondary"
          },
          {
              "id": 2,
              "text": "scheduler.enum.interval.five_minutes",
              "value": 5,
              "variant": "info"
          },
          {
            "id": 3,
            "text": "scheduler.enum.interval.ten_minutes",
            "value": 10,
            "variant": "info"
          },
          {
            "id": 4,
            "text": "scheduler.enum.interval.thirty_minutes",
            "value": 30,
            "variant": "info"
          },
          {
            "id": 5,
            "text": "scheduler.enum.interval.one_hours",
            "value": 60,
            "variant": "info"
          },
          {
            "id": 6,
            "text": "scheduler.enum.interval.five_hours",
            "value": 300,
            "variant": "info"
          },
          {
            "id": 7,
            "text": "scheduler.enum.interval.ten_hours",
            "value": 600,
            "variant": "info"
          },
          {
            "id": 8,
            "text": "scheduler.enum.interval.one_day",
            "value": 1440,
            "variant": "success"
          },
      ];
  }
}