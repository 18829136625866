<template>
  <b-container fluid>
    <b-row class="d-flex justify-content-between">
      <b-col md="3" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">{{
            $t("common.terms.per_page")
          }}</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50 form-control"
          />
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          :label="$t('common.terms.filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              :placeholder="$t('common.terms.type_to_search')"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                {{ $t("common.terms.clear") }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button
        
          variant="success"
          class="my-1 p-50"
          @click="toggleAddSchedule"
          :disabled="userCantEdit"
        >
          <span>+ Add Schedule</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="items">
          <b-table
            :id="getID('table')"
            hover
            borderless
            responsive
            striped
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            show-empty
            :empty-filtered-text="$t('common.terms.no_records_matching')"
            :empty-text="$t('common.terms.no_records_show')"
          >
            <!-- @row-clicked.self="(item) => $router.push({ name: 'transmission-scheduler-view', params: { transmissionID, schedulerID: item.id } })" -->
            <template #cell(name)="data">
              <h5 @click="$router.push({ name: 'transmission-scheduler-view', params: { transmissionID, schedulerID: data.item.id } })">
                <b-badge
                  variant="light-info"
                  class="m-0 font-weight-bolder text-info"
                  >{{ data.item.name }} <b-icon icon="info-circle"
                /></b-badge>
              </h5>
            </template>

            <template #cell(period)="data">
              <div class="d-inline-block mr-2">
                <p class="my-0 small text-muted">From</p>
                <p class="my-0">{{ formatDate(data.item.start_date) }}</p>
              </div>
              <div class="d-inline-block">
                <p class="my-0 small text-muted">To</p>
                <p>
                  <span v-if="data.item.end_date">{{ formatDate(data.item.end_date) }}</span
                  ><span v-else>&#8734;</span>
                </p>
              </div>
            </template>

            <template #cell(active)="data">
              <b-button
                v-if="!loadingActive[data.item.id]"
                class="text-uppercase mr-50 cursor-pointer badge-toggler py-25 px-0 round "
                :variant="data.item.active ? 'success' : 'secondary'"
                @click.self="toggleActive(data.item)"
                :disabled="userCantEdit"
                >{{ data.item.active ? "Active" : "Inactive" }}
                <b-icon
                  :icon="data.item.active ? 'stop-circle' : 'play-circle'"
                />
              </b-button>
              <b-badge v-else :variant="data.item.active ? 'success' : 'secondary'" class="badge-toggler mr-50 badge-padding-fix">
                <b-spinner variant="light" small />
              </b-badge>
            </template>

            <template #cell(last_execution)="data">
              <b-badge
                v-if="data.item.last_execution_at"
                class="text-uppercase"
                variant="light-info"
                pill
                >{{ data.item.last_execution_at }}</b-badge
              >
              <p
                v-else
                class="text-muted small text-uppercase font-weight-bolder"
              >
                Never
              </p>
            </template>

            <template #cell(actions)="data">
              <b-button
                v-b-tooltip.hover
                title="Run once right now"
                class="mr-1"
                size="sm"
                variant="secondary"
                @click="promptRunOnce(data.item)"
                :disabled="userCantEdit"
                ><b-icon icon="play"
              /></b-button>
              <b-button
                v-b-tooltip.hover
                title="View executions"
                class="mr-1"
                size="sm"
                variant="info"
                @click="viewHistory(data.item)"
                :disabled="userCantEdit"
                ><b-icon icon="clock-history"
              /></b-button>
              <b-button
                v-b-tooltip.hover
                title="Delete this schedule"
                :disabled="loadingDelete[data.item.id] || userCantEdit"

                class="mr-1"
                size="sm"
                variant="danger"
                @click="deleteSchedule(data.item)"

                >
                  <b-icon v-if="!loadingDelete[data.item.id]" icon="trash"/>
                  <b-spinner v-else variant="light" small />
              </b-button>
              <b-button
                v-b-tooltip.hover
                :title="
                  data.item.favorite
                    ? 'Remove from favorites'
                    : 'Add to favorites'
                "
                :disabled="favoriteLoading[data.item.id] || userCantEdit"
                class="mr-1"
                :class="
                  data.item.favorite && !favoriteLoading[data.item.id]
                    ? 'text-warning'
                    : 'text-secondary'
                "
                size="sm"
                :variant="
                  data.item.favorite && !favoriteLoading[data.item.id]
                    ? 'outline-warning'
                    : 'outline-secondary'
                "
                @click="toggleFavorite(data.item)"
              >
                <b-icon
                  v-if="!favoriteLoading[data.item.id]"
                  :icon="data.item.favorite ? 'star-fill' : 'star'"
                />
                <b-spinner v-else variant="secondary" small />
              </b-button>
            </template>
          </b-table>
          <div class="d-flex justify-content-center">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :aria-controls="getID('table')"
            ></b-pagination>
          </div>
        </div>
        <div v-else>
          <b-skeleton-table
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true, striped: true }"
          />
        </div>
      </b-col>
    </b-row>

    <b-modal
      :id="getID('modal-logs')"
      hide-footer
      size="xl"
      title="History of executions"
    >

      <schedule-dashboard
        v-if="currentLogScheduler"
        :transmissionID="transmissionID"
        :schedulerID="currentLogScheduler"
        :showGraph="false"
      />
    </b-modal>

    <b-modal
      ref="run-once-modal"
      ok-only
      size="md"
      centered
      ok-title="Confirm"
      :ok-disabled="modalInfo.loading"
      @ok="runScheduleOnce()" 
      @hide="(e)=>{if(modalInfo.loading) e.preventDefault()}"
      @hidden="modalInfo.item = undefined"
    >
      <template #modal-title>
        Execute Scheduler Now
      </template>
      <p class="mb-0">Do you wish to execute this Scheduler once right now?</p>
      <p class="small text-secondary m-0">This won't affect any further executions of the Scheduler</p>
      
      <template #modal-ok>
        <div >
          <span v-if="!modalInfo.loading">Confirm</span> 
          <b-spinner v-else small/>
        </div>
      </template>
    </b-modal>
    
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BFormSelect,
  BFormInput,
  BTable,
  BContainer,
  BSkeletonTable,
  BBadge,
  BPagination,
  BSpinner,
  VBTooltip,
  BModal,
} from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";
import { makeToast } from "@/layouts/components/Popups.js";
import moment from "moment";
import ScheduleDashboard from "./Dashboard.vue";
import { successToast } from '@/custom/class/FunctionClasses/CommonToasts';

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BButton,
    BFormGroup,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormInput,
    BContainer,
    BSkeletonTable,
    BBadge,
    BPagination,
    BSpinner,
    BModal,
    ScheduleDashboard,
  },
  props: {},
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "period",
          label: "Period",
          sortable: true,
        },
        {
          key: "last_execution",
          label: "Last execution at",
          sortable: true,
        },
        {
          key: "active",
          label: "Active",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          width: "400px",
        },
      ],
      pageOptions: [3, 10, 20, 40, 50, 100],
      perPage: 10,
      totalRows: 0,
      currentPage: 1,
      filter: null,
      filterOn: [],
      uuidMap: {},
      favoriteLoading: {},
      loadingActive: {},
      loadingDelete: {},
      currentLogScheduler: undefined,
      modalInfo:{
        loading: false,
        item: undefined,
      }
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    transmissionID() {
      return this.$route.params.transmissionID;
    },
    items() {
      return this.$store.getters.getSchedules(this.transmissionID);
    },
    userCantEdit(){
      return !this.$can('create')
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getSchedules();
    },
    getSchedules() {
      this.$store
        .dispatch("getSchedules", { transmissionID: this.transmissionID })
        .then((resp) => {
          this.totalRows = resp.length;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    toggleAddSchedule() {
      this.$emit("toggleAddSchedule");
    },
    promptRunOnce(item){
      this.modalInfo.item = item;
      this.$refs['run-once-modal'].show()
    },
    runScheduleOnce(){
      let item = structuredClone(this.modalInfo.item);
      if (!item || this.modalInfo.loading){
        return
      }
      this.modalInfo.loading = true
      this.$store
        .dispatch("runScheduleOnce", { transmissionID: this.transmissionID, id: item.id})
        .then((resp) => {
          this.modalInfo.loading = false
          successToast({text: "Scheduler successfully executed"})
          this.$nextTick(()=>{
            this.$refs['run-once-modal'].hide()
          })
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(()=>{
          this.modalInfo.loading = false
        })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    toggleFavorite(item) {
      const i18n_base = "scheduler.toast.change_favorite";
      this.$set(this.favoriteLoading, item.id, true);

      this.$store
        .dispatch("toggleScheduleFavorite", {
          id: item.id,
          transmissionID: this.transmissionID,
          new_value: !item.favorite,
        })
        .then(() => {
          this.$set(this.favoriteLoading, item.id, false);
        })
        .catch(() => {
          makeToast({
            title: this.$t(
              `${i18n_base}.error.title`
            ),
            text: this.$t(
              `${i18n_base}.error.message`
            ),
            variant: "danger",
            icon: "XIcon",
          });

          this.$set(this.favoriteLoading, item.id, false);
        });
    },
    toggleActive(item) {
      const i18n_base = "scheduler.modal.confirm_activate";

      this.$set(this.loadingActive, item.id, true);
      
      this.$bvModal
        .msgBoxConfirm(
          this.$t(`${i18n_base}.message`, {
            schedule: item.name,
            term: item.active
              ? this.$t("common.terms.inactive")
              : this.$t("common.terms.active"),
          }),
          {
            title: this.$t(`${i18n_base}.title`),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("common.terms.yes"),
            cancelTitle: this.$t("common.terms.no"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$store
              .dispatch("toggleScheduleActive", {
                new_value: !item.active,
                transmissionID: this.transmissionID,
                id: item.id,
              })
              .then(() => {
                this.$set(this.loadingActive, item.id, false);
              })
              .catch(() => {
                this.$set(this.loadingActive, item.id, false);

                makeToast({
                  title: this.$t(
                    `${i18n_base}.error.title`
                  ),
                  text: this.$t(
                    `${i18n_base}.error.message`
                  ),
                  variant: "danger",
                  icon: "XIcon",
                });
              });
          } else {
            this.$set(this.loadingActive, item.id, false);
          }
        });
    },
    deleteSchedule(item) {
      const i18n_base = "scheduler.modal.confirm_delete";
      this.$set(this.loadingDelete, item.id, true);

      this.$bvModal
        .msgBoxConfirm(
          this.$t(`${i18n_base}.message`, {
            schedule: item.name,
          }),
          {
            title: this.$t(`${i18n_base}.title`),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("common.terms.yes"),
            cancelTitle: this.$t("common.terms.no"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$store
              .dispatch("deleteSchedule", {
                transmissionID: this.transmissionID,
                id: item.id,
              })
              .then(() => {
                this.$set(this.loadingDelete, item.id, false);

                makeToast({
                  title: this.$t(
                    `${i18n_base}.success.title`
                  ),
                  text: this.$t(
                    `${i18n_base}.success.message`
                  ),
                  variant: "success",
                  icon: "CheckIcon",
                });
              })
              .catch(() => {
                this.$set(this.loadingDelete, item.id, false);

                makeToast({
                  title: this.$t(
                    `${i18n_base}.error.title`
                  ),
                  text: this.$t(
                    `${i18n_base}.error.message`
                  ),
                  variant: "danger",
                  icon: "XIcon",
                });
              });
          } else {
            this.$set(this.loadingDelete, item.id, false);
          }
        });
    },
    formatDate(value) {
      if (!value) return null
      let date = new Date(value)
      return moment(date).format(this.$t('scheduler.view.moment_date_format'));
    },
    viewHistory(item) {
      this.currentLogScheduler = item.id;

      this.$bvModal.show(this.getID('modal-logs'));
    }
  },
};
</script>

<style lang="scss" scoped>

.badge-toggler {
  width: 100px !important;
}

.badge-padding-fix {
  padding-top: 0.43rem !important;
  padding-bottom: 0.43rem !important;
}

</style>