<template>
  <div>
    <b-container fluid>
      <scheduler-table
        :items="items"
        :fields="fields"
        @toggleAddSchedule="toggleAddSchedule"
      />
    </b-container>
    <add-schedule-sidebar
      :uuid="getID('add-schedule')"
      @close="toggleAddSchedule"
    />
  </div>
</template>

<script>
import { 
  BContainer, 
  BRow, 
  BCol, 
  BTable, 
  BButton,  
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BForm,
  BFormGroup,
  BFormSelect, 
} from 'bootstrap-vue'
import AddScheduleSidebar from '@/layouts/components/Scheduler/AddSchedule.vue'
import SchedulerTable from '@/layouts/components/Scheduler/SchedulerTable.vue'
import { v4 as uuidv4 } from 'uuid'

  export default {
    components: {
      BContainer, 
      BRow, 
      BCol,
      BTable,
      BButton,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BForm,
      BFormGroup,
      BFormSelect,
      AddScheduleSidebar,
      SchedulerTable,
    },
    data() {
      return {
        uuidMap: {},
        fields: [
          { key: 'name', label: 'name', sortable: true},
          { key: 'status', label: 'Active', sortable: true},
          { key: 'Chart', label: 'chart', sortable: true},
          { key: 'actions', label: 'actions', thStyle: "width: 350px;" },
        ],
        items: [
          { 
            name: "Droponcio",
            status: true,
            chart: "graficquin",
            _showDetails: false
          }
        ],
        areaChartOptions: {
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
            },
          },
          chart: {
            toolbar: {
              show: false,
            },
            sparkline: {
              enabled: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2.5,
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 0.9,
              opacityFrom: 0.5,
              opacityTo: 0.2,
              stops: [0, 80, 100],
            },
          },
          xaxis: {
            type: 'numeric',
            lines: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            labels: { show: false },
          },
          yaxis: [
            {
              y: 0,
              offsetX: 0,
              offsetY: 0,
              padding: {
                left: 0,
                right: 0,
              },
            },
          ],
          tooltip: {
            x: { show: false },
          },
          theme: {
            monochrome: {
              enabled: true,
              color: 'primary',
              shadeTo: 'light',
              shadeIntensity: 0.65,
            },
          },
        }
      }
    },
    computed: {
      chartOptionsComputed() {
        if (this.chartOptions === null) {
          const options = JSON.parse(JSON.stringify(areaChartOptions))
          options.theme.monochrome.color = $themeColors[this.color]
          return options
        }
        return this.chartOptions
      },
    },
    methods: {
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      toggleAddSchedule() {
        this.$root.$emit(
          "bv::toggle::collapse",
          this.getID("add-schedule")
        );
      }
    }
  }
</script>

<style lang="scss" scoped>
.thead-rounded {
  .tr-rounded {
    th {
      border-top-left-radius: 15px !important;
    }
  }
}
</style>