<template>
  <div>
    <div>
      <b-container fluid>
        <b-row>
          <b-col cols="12" class="my-1 text-center">
            <b-form-group class="mb-0 d-inline-block text-left">
              <label class="d-inline-block text-sm-left mr-50 text-uppercase">{{
                $t("common.terms.from")
              }}</label>
              <flat-pickr
                placeholder="No date selected"
                v-model="from"
                class="form-control"
                :config="flatPickrConfig"
              />
            </b-form-group>
            <b-form-group class="mb-0 d-inline-block ml-2 text-left">
              <label class="d-inline-block text-sm-left mr-50 text-uppercase">{{
                $t("common.terms.to")
              }}</label>
              <flat-pickr
                placeholder="No date selected"
                v-model="to"
                class="form-control"
                :config="flatPickrConfig"
              />
            </b-form-group>
            <b-form-group class="mb-0 d-inline-block ml-2 text-left">
              <b-button b-button variant="info" @click="refreshItems">
                <span v-if="!loadingItems">Filter</span>
                <b-spinner v-else small variant="light" />
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-col cols="12" v-if="showGraph && from && from.toString().length <= 11">
      <scheduler-charts
        ref="schedule-graph"
        :to="to"
        :from="from"
      />
    </b-col>
    <b-col v-if="showTable && from && from.toString().length <= 11">
      <log-table
        ref="log-table"
        id="log-table"
        :scrollContainer="scrollContainer"
        :scroll_id="scroll_id"
        v-model="items"
        @created="getScheduleLogs"
        @goNextPage="goNextPage"
      />
    </b-col>
  </div>
</template>

<script>
import { BContainer, BRow, BCard, BCardText, BLink, BButton, BCol, BFormGroup, BIcon, BSpinner  } from 'bootstrap-vue'

import SchedulerCharts from '@/layouts/components/Scheduler/SchedulerCharts.vue'
import LogTable from '@/layouts/components/Dashboard/LogTable.vue'

import moment from "moment";
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
    BCol,
    SchedulerCharts,
    LogTable,
    flatPickr,
    BIcon,
    BFormGroup,
    BContainer,
    BRow,
    BSpinner
  },
  props: {
    transmissionID: {
      type: [ String, Number ],
      required: true
    },
    schedulerID: {
      type: [ String, Number ],
      required: true
    },
    showTable: {
      type: Boolean,
      default: true
    },
    showGraph: {
      type: Boolean,
      default: true
    },
    scrollContainer: {
      type: String,
      default: '.modal'
    }
  },
  data() {
    return {
      flatPickrConfig: { enableTime: true, dateFormat: 'U', altInput: true, altFormat: 'F j, Y H:i', time_24hr: true, minuteIncrement: 1, },
      from: null,
      to: null,

      items: null,
      loadingItems: false,

      scroll_id: undefined,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.setDefaultInterval()
    },
    refreshItems() {
      this.getScheduleLogs()
      if (this.$refs['schedule-graph']) this.$refs['schedule-graph'].init();
    },
    setDefaultInterval() {
      this.to = Date.now()

      let last_week = (Date.now() - (7 * 24 * 60 * 60 * 1000));
      this.from = last_week;
    },
    getScheduleLogs() {
      this.items = undefined;

      this.$store.dispatch('getScheduleLogs', {
        transmission_id: this.transmissionID,
        scheduler_id: this.schedulerID,
        from: this.from,
        to: this.to,
      }).then((resp) => {
        this.scroll_id = resp._scroll_id.replace('==', '');
        this.items = resp.hits.hits;

        if(this.items[0] && !this.to) {
          this.to = this.items[0]._source.created_at;
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    goNextPage() {
      if(!this.items || !this.items.length) return;
      
      this.$refs['log-table'].searchingNextPage = true;

      this.$store.dispatch('getScheduleLogsPaginated', {
        transmission_id: this.transmissionID,
        scroll_id: this.scroll_id,
      }).then((resp) => {
        if(resp.hits.hits.length === 0) {
          this.scroll_id = undefined;
        } else {
          this.items.push(...resp.hits.hits);
        }

        this.$refs['log-table'].searchingNextPage = false;
      }).catch((err) => {
        console.log(err);
        this.$refs['log-table'].searchingNextPage = false;
      });
    },
  }
}
</script>

<style lang="scss">
</style>

